import React from 'react'
import { EmotionStyles } from '~/types/types'
import Link from 'next/link'

import env from '~/constants/env'
const PUBLIC_ASSET_PREFIX = env.PUBLIC_ASSET_PREFIX

export const LogoLink: React.FC<{ imageCss?: EmotionStyles }> = ({ imageCss }) => {
  return (
    <Link href="/" passHref>
      <a>
        <img src={`${PUBLIC_ASSET_PREFIX}/static/icons/Joblist_Logo_Black.svg`} css={imageCss} />
      </a>
    </Link>
  )
}
