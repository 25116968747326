import React from 'react'
import Link from 'next/link'
import Icon from '../../common/Icon'
import { css } from '@emotion/core'
import { profileUrl, loginUrl } from '~/lib/links'
import { AnchorButton } from '~/components/common/Button/Button'
import { screenSizeType, mqSizeMap } from '~/constants/theme.styles'
import { useMeQuery } from '~/generated/graphql'

// i18n support
import { LogInLabel, ProfileLabel } from '~/lang/langFile'
import { useLang } from '~/context/LangContext'

interface Props {
  hideTextAt?: screenSizeType
}

export const ProfileMenuLink: React.FC<Props> = ({ hideTextAt }) => {
  const { data: meData } = useMeQuery({ fetchPolicy: 'cache-only' })
  const currentUser = meData?.me
  const { lang } = useLang()

  return (
    <Link href={currentUser ? profileUrl : loginUrl} passHref>
      <AnchorButton
        color="white"
        rounded
        extraCss={[buttonStyles, hideTextAt ? roundedButtonStyles(hideTextAt) : null]}
      >
        <Icon
          icon="profile"
          color="black"
          css={css`
            font-size: 18px;
            background: transparent;
            position: relative;
            top: -1px;
          `}
        />
        <span css={[buttonText, hideTextAt ? hideTextAtStyles(hideTextAt) : null]}>
          {currentUser ? ProfileLabel(lang)[0] : LogInLabel(lang)[0]}
        </span>
      </AnchorButton>
    </Link>
  )
}

const buttonStyles = css`
  padding: 10px 13px;
`

const buttonText = css`
  font-size: 14px;
  font-weight: 600;
  margin-left: 8px;
`

const roundedButtonStyles = (screenSize: screenSizeType) => {
  return css`
    @media ${mqSizeMap[screenSize]} {
      width: 39px;
      height: 39px;
      padding: 0;
    }
  `
}

const hideTextAtStyles = (screenSize: screenSizeType) => {
  return css`
    @media ${mqSizeMap[screenSize]} {
      display: none;
    }
  `
}
